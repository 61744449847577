import React from "react";

const BitcoinButton = ({ handleBitcoinClick }) => {
  return (
    <button
      onClick={handleBitcoinClick}
      className="w-full max-w-[300px]  bg-yellow-500 text-white py-2 flex items-center justify-center rounded-3xl shadow-md hover:bg-yellow-600 transition border border-yellow-700"
    >
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/4/46/Bitcoin.svg"
        alt="Bitcoin"
        className="h-6 w-10 mr-3"
      />
      Pay with Bitcoin
    </button>
  );
};

export default BitcoinButton;
