import React, { useEffect, useState } from "react";

const PayPalButton = () => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    // Ensure the component is mounted
    setIsMounted(true);

    if (isMounted && window.paypal) {
      window.paypal
        .HostedButtons({
          hostedButtonId: "WWYJZRN8Q385J",
        })
        .render("#paypal-container-WWYJZRN8Q385J")
        .catch((err) => {
          console.error("PayPal Button render error:", err);
        });
    }

    // Clean up when the component unmounts
    return () => setIsMounted(false);
  }, [isMounted]);

  return <div id="paypal-container-WWYJZRN8Q385J"></div>;
};

export default PayPalButton;
