import React from 'react';
import { Link } from 'react-router-dom';

const Breadcrumb = ({ currentStep }) => {
  return (
    <div>
      <ul className="list-none border-b border-[#f2f4f9] py-3 font-[500] flex flex-wrap items-center space-x-2">
        <li className="flex items-center">
          <Link
            to="/payment"
            className={`no-underline text-[.9375rem] font-lato ${
              currentStep === "listing" ? "text-[#5c6575]" : "text-[#6875E2]"
            }`}
          >
            Job listing
          </Link>
          <span className="mx-2 text-[15px] text-[#5c6575]">&gt;</span>
        </li>

        <li className="flex items-center">
          <Link
            to="/"
            className={`no-underline text-[.9375rem] font-lato ${
              currentStep === "details" ? "text-[#5c6575]" : "text-[#6875E2]"
            }`}
          >
            Job details
          </Link>
          {(currentStep === "application" || currentStep === "payment") && (
            <span className="mx-2 text-[15px] text-[#5c6575]">&gt;</span>
          )}
        </li>

        {(currentStep === "application" || currentStep === "payment") && (
          <li className="flex items-center">
            <Link
              to="/job-application"
              className={`no-underline text-[.9375rem] font-lato ${
                currentStep === "application" ? "text-[#5c6575]" : "text-[#6875E2]"
              }`}
            >
              Job application
            </Link>
            {currentStep === "payment" && (
              <span className="mx-2 text-[15px] text-[#5c6575]">&gt;</span>
            )}
          </li>
        )}

        {currentStep === "payment" && (
          <li className="flex items-center">
            <Link
              to="/payment"
              className="no-underline text-[.9375rem] font-lato text-[#5c6575]"
            >
              Payment
            </Link>
          </li>
        )}
      </ul>
    </div>
  );
};

export default Breadcrumb;
