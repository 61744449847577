import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "../components/Breadcrumb";
import SkeletonComp from "../components/Skeleton";
import axios from 'axios';
import PayPalButton from "./PayPalButton";
import BitcoinButton from "./BitcoinButton";

const Payment = () => {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
      const centerY = window.innerHeight / 2;
      window.scrollTo({
        top: centerY,
        behavior: "smooth",
      });
    }, 2000);
  }, []);

  const handlePayPalClick = async () => {
    try {
      const response = await axios.post('http://localhost:8000/api/payments/', {
        payment_type: 'paypal', // Specify the payment type
      });
      
      // Redirect to PayPal approval URL
      const approvalUrl = response.data.links.find(link => link.rel === 'approval_url').href;
      window.location.href = approvalUrl; // Redirect user to PayPal for approval
    } catch (error) {
      console.error("Error initiating PayPal payment:", error);
    }
  };

  const handleCardClick = () => {
    navigate("/card-payment");
  };

  const handleBitcoinClick = () => {
    navigate("/bitcoin-payment");
  };

  return (
    <div className="opacity-100 relative z-4 w-full min-w-[300px] max-w-[1024px] mx-auto">
      {isLoading ? (
        <div>
          <SkeletonComp />
        </div>
      ) : (
        <>
          <div className="w-full max-w-[1024px] mx-auto px-4 sm:px-6 md:px-8">
            <Breadcrumb currentStep="payment" />
            <div className="flex flex-col items-center mt-12 font-lato border border-transparent rounded-lg shadow-lg p-4 bg-white">
              <h2 className="text-3xl font-lato font-bold mb-8 text-center">
                Select Your Payment Method
              </h2>
              <div className="space-y-6 w-full flex flex-col items-center">
                <PayPalButton />
                <BitcoinButton />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Payment;
