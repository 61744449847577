// src/pages/PaymentSuccess.js
import React from 'react';

const PaymentSuccess = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <h1 className="text-3xl font-bold">Payment Successful!</h1>
      <p className="mt-4">Thank you for your payment. Your transaction was successful.</p>
      {/* You can add additional information or redirect options here */}
    </div>
  );
};

export default PaymentSuccess;
