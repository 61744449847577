// src/pages/NotFound.js
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();

  // Automatically redirect after a delay (e.g., 3 seconds)
  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/");
    }, 2000); // Change this number to adjust the delay in milliseconds

    return () => clearTimeout(timer); // Cleanup on unmount
  }, [navigate]);

  return (
    <div className="flex items-center justify-center my-10 bg-gray-100 p-4">
      <div className="text-center">
        <h1 className="text-6xl font-bold text-blue-600 mb-4">404</h1>
        <h2 className="text-2xl font-semibold text-gray-700 mb-2">
          Page Not Found
        </h2>
        <p className="text-gray-500 mb-6">
          Sorry, the page you are looking for does not exist. Please check the URL or return to the homepage.
        </p>
      
      </div>
    </div>
  );
};

export default NotFound;
