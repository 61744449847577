import React from 'react'
import Payment from '../components/Payment'

const PaymentPage = () => {
  return (
    <div>
        <Payment/>
    </div>
  )
}

export default PaymentPage