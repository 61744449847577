// src/pages/PaymentCancel.js
import React from 'react';

const PaymentCancel = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <h1 className="text-3xl font-bold">Payment Canceled</h1>
      <p className="mt-4">Your payment was canceled. Please try again.</p>
      {/* You can add options to go back to the payment page or main page */}
    </div>
  );
};

export default PaymentCancel;
