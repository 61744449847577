// src/App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import BackToTopButton from "./components/BackToTopButton";
import Footer from "./components/Footer";
import Hero from "./components/Hero";
import JobDetails from "./components/JobDetails";
import JobApplication from "./pages/JobApplication";
import PaymentPage from "./pages/PaymentPage";
import PaymentSuccess from "./pages/PaymentSuccess";  // Import the PaymentSuccess component
import PaymentCancel from "./pages/PaymentCancel";    // Import the PaymentCancel component
import NotFound from "./pages/NotFound";              // Import the NotFound component

function App() {
  return (
    <Router>
      <div className="App">
        <Hero jobTitle="Data Entry Specialist" />
        <Routes>
          <Route path="/" element={<><JobDetails /></>} />
          <Route path="/job-application" element={<JobApplication />} />
          <Route path="/payment" element={<PaymentPage />} />
          <Route path="/payment-success" element={<PaymentSuccess />} /> {/* Add success route */}
          <Route path="/payment-cancel" element={<PaymentCancel />} /> {/* Add cancel route */}
          <Route path="*" element={<NotFound />} /> {/* Catch-all route for undefined paths */}
        </Routes>
        <BackToTopButton />
        <Footer />
      </div>
    </Router>
  );
}

export default App;
